body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  overflow: hidden;
}

body {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
}

.header {
  height: 42px;
  width: 100%;
}

.notHeader {
  height: calc(100% - 42px);
  width: 100%;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  display: block;
}

.loader {
  height: 100%;
}

.leftDisplay {
  float: left;
  width: 20%;
  height: 100%;
  padding: 0px;  
}

.rightDisplay {
  float: left;
  width: 80%;
  height: 100%;
  padding: 0px;
}

.mapDisplay {
  float: left;
  width: 60%;
  height: 100%;
  padding: 0px;  
}

.logDisplay {
  float: left;
  width: 40%;
  height: 100%;
  padding: 0px;
}

.resource-grid {
  display: grid;
}

.resource-column-1 {
  grid-column: 1;
}

.resource-column-2 {
  grid-column: 2;
}

.resource-column-3 {
  grid-column: 3;
}

.redColor {
  color: red;
}

.catanBackground1 {
  background-image: url(/images/header_back.jpg);
}

.catanBackground2 {
  background-image: url(/images/catanback.jpg);
}

.status-grid {
  display: grid;
}

.status-column-1 {
  grid-column: 1;
}

.status-column-2 {
  grid-column: 2;
}

.status-column-3 {
  grid-column: 3 / span 12;
}

.vic-cell{
  text-align: center;
  display: inline-block;
  width: 150px;
}